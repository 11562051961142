import { useEffect, useState } from 'react';

import SingleOptionCard from 'Organisms/SingleOptionCard/SingleOptionCard';
import { CardIcon } from 'Atoms/Icons';
import FormSubmissionB2C from 'Models/Pages/CheckoutPage/FormSubmissionB2C.interface';

import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { styled } from 'stitches.config';

import PaymentProviders, { PaymentMethods } from '../PaymentProviders';
import KexCartModel from 'Models/Cart/KexCartModel.interface';
import Button from 'Atoms/Buttons/Button';
import { RequestStatus } from '../DeliveryMethods/DeliveryMethodAPI';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import ErrorTextWithIcon from 'Atoms/Error/ErrorTextWithIcon';

type PropType = {
  addressPayload: FormSubmissionB2C;
  currentPaymentMethod: string;
  onChangePaymentMethod: (paymentType: string) => Promise<void>;
  cart: KexCartModel;
  setErrorTermsConditions: (value: boolean) => void;
  acceptedTermsConditions: boolean;
};

function PayExPayment({
  addressPayload,
  currentPaymentMethod,
  onChangePaymentMethod,
  cart,
  setErrorTermsConditions,
  acceptedTermsConditions,
}: PropType) {
  const {
    translations: {
      checkoutLabels: { cardPayment, cardPaymentDescription },
      accountLabels: { tryAgain },
      errorLabels: { technicalError, technicalErrorDescription },
    },
    availablePaymentMethods,
  } = useAppSettingsData();

  const [onState, setOnState] = useState<RequestStatus>('');
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);

  const openPayEx = PaymentProviders(setOnState).openPayEx;

  async function cleanupPayExElements() {
    const payExWidgetElement = document.getElementById('PayEx');

    if (payExWidgetElement) {
      document.getElementById('payex-script')?.remove();

      while (payExWidgetElement.firstChild) {
        payExWidgetElement.removeChild(payExWidgetElement.firstChild);
      }
    }
  }

  async function loadPayExWidget(paymentType: string) {
    if (!acceptedTermsConditions) {
      setErrorTermsConditions(true);

      return;
    }

    await cleanupPayExElements();

    await onChangePaymentMethod(paymentType);

    openPayEx(addressPayload, PaymentMethods.PayEx);
  }

  useEffect(() => {
    if (currentPaymentMethod === PaymentMethods.PayEx) {
      cleanupPayExElements();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, addressPayload]);

  return (
    <>
      {availablePaymentMethods && availablePaymentMethods.payEx && (
        <div>
          <SingleOptionCard
            onClick={() => {
              loadPayExWidget(PaymentMethods.PayEx);
            }}
            isActive={PaymentMethods.PayEx === currentPaymentMethod}
            image={
              <PaymentIcon>
                <CardIcon size="l" color="primary" />
              </PaymentIcon>
            }
            content={
              <PaymentContent>
                <PaymentHeading>{cardPayment}</PaymentHeading>
                <PaymentSubHeading>{cardPaymentDescription}</PaymentSubHeading>
              </PaymentContent>
            }
            radioBg="secondary"
          />
          <PaymentContainer
            id={PaymentMethods.PayEx}
            isWidgetHidden={
              currentPaymentMethod !== PaymentMethods.PayEx ||
              onState === 'error'
            }
          />
          {onState === 'error' &&
            currentPaymentMethod === PaymentMethods.PayEx && (
              <ErrorTextContainer>
                <ErrorTextWithIcon
                  errorHeader={technicalError}
                  errorDescription={technicalErrorDescription}
                />
                <Button
                  css={{ marginTop: '12px' }}
                  type="secondary"
                  size="s"
                  onClick={async () => {
                    setOnState('');
                    await cleanupPayExElements();
                    await onChangePaymentMethod('');
                  }}
                  {...(!isDesktop && { fullWidth: true })}
                >
                  {tryAgain}
                </Button>
              </ErrorTextContainer>
            )}
        </div>
      )}
    </>
  );
}

export default PayExPayment;

export const PaymentContent = styled('div', {
  minH: '80px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  py: 4,
  px: 6,
  backgroundColor: '$primary3',
});

export const PaymentHeading = styled('span', {
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  fontFamily: '$fontPrimary500',
  ls: 'lsn1',
  color: '$secondary2',
  wordSpacing: '$wordSpacings$fontPrimary',
  fs: 8,
  lineHeight: '$lh15',
  '@mediaMinLarge': {
    fs: 9,
    lineHeight: '$lh155',
  },
});

const PaymentSubHeading = styled('span', {
  fs: 6,
  color: '$secondary2',
  lineHeight: '$lh133',
  textAlign: 'left',
});

export const PaymentIcon = styled('div', {
  pl: 4,
  pt: 7,
  display: 'flex',
  width: '70px',
  alignItems: 'flex-start',
  justifyContent: 'center',
  backgroundColor: '$primary3',
});

const PaymentContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '$primary3',
  overflow: 'hidden',
  variants: {
    isWidgetHidden: {
      false: {
        pt: 5,
        pb: 8,
        height: 'auto',
      },
      true: {
        height: 0,
      },
    },
  },
});

const ErrorTextContainer = styled('div', {
  padding: '32px',
});
