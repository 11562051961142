import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import { GetCart } from 'Shared/Cart/Cart';
import { GetMotorcycleStock } from 'Shared/Cart/Stock';
import useMedia from 'Shared/Hooks/useMedia';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { useUserStateData } from 'Shared/Providers/UserContextProvider';
import { styled } from 'stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import CheckoutAccount from './CheckoutAccount';
import OrderSummary from './OrderSummary/OrderSummary';
import CheckoutCustomerInformation from './CheckoutCustomerInformation';
import { useState, useCallback } from 'react';
import KexLink from 'Kex/KexLink';
import { BackIcon, LogoIcon, UserIcon } from 'Atoms/Icons';
import {
  PropType as AccountModalPropType,
  ModalName,
} from 'Organisms/AccountModal/AccountModal';
import loadable, { LoadableComponent } from '@loadable/component';
import { canUseDOM } from 'Shared/Common/Helpers';
import { SizeConstants } from 'Theme/Settings/sizes';
import { useKexNavigate } from 'Kex/KexRouter/KexRouter';

const AccountModal: LoadableComponent<AccountModalPropType> = loadable(
  () => import('Organisms/AccountModal/AccountModal')
);

function CheckoutPage() {
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);
  const {
    languageRoute,
    staticPages: { accountPage },
  } = useAppSettingsData();
  const {
    accountDispatch,
    account,
    user: { authenticated },
  } = useUserStateData();

  const kexNavigate = useKexNavigate();

  const [continueAsGuest, setContinueAsGuest] = useState<boolean>(false);

  const { cart, isLoading, hasMotorCycle, isCartEmpty, triggerRevalidation } =
    GetCart(languageRoute);
  const { motorcycleStock } = GetMotorcycleStock();

  const isMotorcycleOutOfStock =
    hasMotorCycle && motorcycleStock.isInStock === false;

  const toggle = useCallback(() => {
    accountDispatch({ type: 'toggle' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountDispatch]);

  const redirectPrevUrl = () => {
    if (!canUseDOM()) return;

    const prevUrl = sessionStorage.getItem('url');

    if (!prevUrl) {
      window.location.href = `${window.location.origin}/${languageRoute}`;
    }

    kexNavigate(prevUrl ?? '');
  };

  return (
    <>
      <CheckoutHeader id="checkout-header">
        <ContentContainer>
          <CheckoutHeaderInner>
            <StyledKexLink
              onClick={redirectPrevUrl}
              underlined={false}
              aria-label={'back'}
            >
              <BackIcon size="m" color="secondary" />
            </StyledKexLink>
            <StyledKexLink
              href={`/${languageRoute}`}
              underlined={false}
              aria-label={'home link'}
            >
              <LogoIcon
                size={isDesktop ? 'headerDesktop' : 'headerMobile'}
                fillColor="white"
              />
            </StyledKexLink>
            {authenticated ? (
              <KexLink css={{ m: 0 }} href={accountPage} underlined={false}>
                <UserIcon size="m" color="primary" />
              </KexLink>
            ) : (
              <AccountButton
                onClick={() => accountDispatch({ type: 'toggle' })}
              >
                <UserIcon size="m" color="secondary" />
              </AccountButton>
            )}
          </CheckoutHeaderInner>
        </ContentContainer>
      </CheckoutHeader>
      <ContentContainer>
        <CheckoutContainer>
          {isDesktop && cart && (
            <OrderSummary
              cart={cart}
              revalidateCart={triggerRevalidation}
              isLoading={isLoading}
              noPadding
            />
          )}

          <CheckoutWrapper>
            {(!isMotorcycleOutOfStock || !hasMotorCycle) && (
              <CheckoutAccount
                continueAsGuest={continueAsGuest}
                setContinueAsGuest={() => setContinueAsGuest(true)}
              />
            )}

            {!isDesktop && cart && (
              <OrderSummary
                revalidateCart={triggerRevalidation}
                cart={cart}
                isLoading={isLoading}
                noPadding
              />
            )}
            {!isCartEmpty && (
              <CheckoutCustomerInformation
                includedMotorcycle={cart?.includedMotorcycle || false}
                continueAsGuest={continueAsGuest}
                revalidateCart={triggerRevalidation}
              />
            )}
          </CheckoutWrapper>
        </CheckoutContainer>
      </ContentContainer>

      <AccountModal
        showModal={account.userMenuIsOpen}
        toggle={toggle}
        clearTokens={() => {}}
        initialModalState={{
          name: ModalName.LOGIN,
        }}
      />
    </>
  );
}

export default CheckoutPage;

const CheckoutHeader = styled('div', {
  backgroundColor: '$primary4',
  py: 5,
  height: SizeConstants.mobileHeaderHeight,
  display: 'flex',
  alignItems: 'center',
  '@mediaMinLarge': {
    height: SizeConstants.desktopHeaderUpperHeight,
  },
});

const CheckoutHeaderInner = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const CheckoutWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 32,
});

const CheckoutContainer = styled('div', {
  my: 8,
  display: 'block',
  gridTemplateColumns: '1fr',
  gap: 32,
  '@mediaMinLarge': {
    display: 'grid',
    my: 16,
    gridTemplateColumns: '1fr 1.6fr',
  },
});

const StyledKexLink = styled(KexLink, {
  mb: 0,
});

const AccountButton = styled('button', {});
