import ProductList from 'Organisms/ProductList/ProductList';
import { styled } from 'stitches.config';
import CartPaymentInformation, {
  PriceColumn,
} from 'Molecules/PaymentInformation/PaymentInformation';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { H3 } from 'Atoms/Typography/Headings/Heading';
import Paragraph from 'Atoms/Typography/Paragraph/Paragraph';
import { useEffect, useState } from 'react';
import Divider from 'Atoms/Divider/Divider';
import Checkbox from 'Atoms/Checkbox/Checkbox';
import KexCartModel from 'Models/Cart/KexCartModel.interface';
import { ApplyMountingCost } from 'Shared/Cart/Cart';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import Price from 'Atoms/Price/Price';
import { Discount } from '../Discount';

type PropType = {
  isLoading: boolean;
  noPadding?: boolean;
  cart: KexCartModel;
  revalidateCart: () => void;
};

const ORDER_SUMMARY_PADDING = 32;

function OrderSummary({
  cart,
  revalidateCart,
  isLoading,
  noPadding = false,
}: PropType) {
  const { languageRoute } = useAppSettingsData();
  const {
    checkoutLabels: { yourOrder },
    cartLabels: { cartEmptyTitle, cartEmptyDescription, mountingAccessory },
    commonLabels: { loading },
  } = useTranslationData();

  const {
    lineItems,
    totalPrice,
    totalPriceForItems,
    totalVATPrice,
    numberOfItems,
    totalFreightPrice,
    mountingCostNowPrice,
    mountingCostWasPrice,
    areMountingCostsApplied,
    canMountingCostBeApplied,
    appliedCouponDiscount,
    promotions,
    hasReachedFreeFreight,
    shippingOptionId,
  } = cart;

  const [stickyHeaderFix, setStickyHeaderFix] = useState({ top: 0 });

  useEffect(() => {
    const header = document.getElementById('checkout-header');

    function adjustForHeaderPosition() {
      const bottomOfHeader = header?.getBoundingClientRect().bottom;

      if (bottomOfHeader !== undefined) {
        const top = Math.max(0, bottomOfHeader) + ORDER_SUMMARY_PADDING;
        setStickyHeaderFix({ top });
      }
    }

    document.addEventListener('scroll', adjustForHeaderPosition);

    return () => {
      document.removeEventListener('scroll', adjustForHeaderPosition);
    };
  });

  const isEmptyCart = numberOfItems === 0;

  return (
    <Main>
      <CartContainer noPadding={noPadding} css={stickyHeaderFix}>
        {isLoading ? (
          <div>{loading}</div>
        ) : isEmptyCart ? (
          <PaddingWrapper>
            <H3 css={StyledEmptyCartTitle}>{cartEmptyTitle}</H3>
            <Paragraph>{cartEmptyDescription}</Paragraph>
          </PaddingWrapper>
        ) : (
          <>
            <CartContent>
              <PaddingWrapper>
                <Wrapper>
                  <H3 css={HeadingStyle}>{yourOrder}</H3>
                  {/* {allowEdit && <Edit onClick={toggleEdit}> {change} </Edit>} */}
                </Wrapper>
              </PaddingWrapper>
              <ProductListWrapper>
                <ProductList
                  products={lineItems}
                  isLoading={false}
                  allowEdit={true}
                  isMiniCart={false}
                />
              </ProductListWrapper>
              {canMountingCostBeApplied && (
                <MountAccessory
                  onClick={(e) => {
                    e.preventDefault();
                    ApplyMountingCost(languageRoute, !areMountingCostsApplied);
                  }}
                >
                  <MountAccessoryInner>
                    <Checkbox
                      text={`${mountingAccessory} `}
                      isChecked={areMountingCostsApplied}
                      onCheck={() => {}}
                    />
                    <Price
                      nowPrice={mountingCostNowPrice.price}
                      wasPrice={mountingCostWasPrice.price}
                      friendlyNowPrice={`+${mountingCostNowPrice.priceRoundedAsString}`}
                      friendlyWasPrice={
                        mountingCostWasPrice.priceRoundedAsString
                      }
                    />
                  </MountAccessoryInner>
                </MountAccessory>
              )}
            </CartContent>
            <PaddingWrapper>
              <Divider type="max" css={{ m: 0 }} />
              <Discount
                discount={cart.appliedCouponDiscount}
                revalidateCart={revalidateCart}
              />

              <CartPaymentInformation
                totalPrice={totalPrice}
                totalVATPrice={totalVATPrice}
                totalFreightPrice={totalFreightPrice}
                totalPriceForItems={totalPriceForItems}
                hasReachedFreeFreight={hasReachedFreeFreight}
                shippingOptionId={shippingOptionId}
                renderPromotions={
                  <>
                    {appliedCouponDiscount && (
                      <>
                        <PriceColumn isDiscount>
                          <span>
                            {appliedCouponDiscount.isTradeIn && <>Inbyte:</>}{' '}
                            {appliedCouponDiscount.rewardName}
                          </span>
                          <span>
                            {appliedCouponDiscount.isTradeIn
                              ? appliedCouponDiscount.tradeInAmount
                                  .priceRoundedAsString
                              : appliedCouponDiscount.totalDiscount
                                  .priceRoundedAsString}
                          </span>
                        </PriceColumn>
                      </>
                    )}
                    {promotions.map((p, i) => {
                      return (
                        <PriceColumn isDiscount key={`${p.promotionName}${i}`}>
                          <span>{p.promotionName}</span>
                          <span>
                            {p.promotionDiscountAmount.priceRoundedAsString}
                          </span>
                        </PriceColumn>
                      );
                    })}
                  </>
                }
              />
            </PaddingWrapper>
          </>
        )}
      </CartContainer>
    </Main>
  );
}

const StyledEmptyCartTitle = {
  mt: 0,
  mb: 4,
  fs: 8,
  lineHeight: '$lh125',
};

const CartContainer = styled(
  'div',
  {
    display: 'flex',
    flexDirection: 'column',
    w: '100%',
    h: 'auto',
    justifyContent: 'center',
    my: 0,
    mx: 0,
    py: 8,
    backgroundColor: '$checkoutPageBackgroundSecondary',
    '@mediaMinLarge': {
      maxW: 132,
    },
    '@mediaMinMedium': {
      position: 'sticky',
      t: 24,
    },
    variants: {
      noPadding: {
        true: {
          px: 0,
        },
      },
    },
  },
  {
    '@mediaMinMedium': {
      position: 'webkit-sticky',
      t: 24,
    },
  }
);

const PaddingWrapper = styled('div', {
  px: 4,
  '@mediaMinLarge': {
    px: 8,
  },
});

// const Edit = styled('span', {
//   lineHeight: '$lh15',
//   fs: 6,
//   display: 'flex',
//   justifySelf: 'end',
//   textDecoration: 'underline',
//   cursor: 'pointer',
//   fontFamily: '$fontSecondary400',
//   h: '100%',
//   '@mediaMinLarge': {
//     fs: 8,
//   },
// });

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const CartContent = styled('div', {
  fontWeight: '$fw300',
  wh: '100%',
  color: '$cartContentTextPrimary',
  mt: 0,
});

const ProductListWrapper = styled('div', {});

const Main = styled('div', {
  backgroundColor: '$cartArticlesBackgroundPrimary',
  height: 'auto',
});

const HeadingStyle = {
  mb: 3,
  mt: 0,
};

const MountAccessory = styled('div', {
  width: '100%',
  my: 4,
  py: 2,
  px: 4,
  '@mediaMinLarge': { px: 8 },
});

const MountAccessoryInner = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '$primary2',
  gap: 4,
  width: '100%',
  py: 2,
  px: 4,
});

export default OrderSummary;
