import { StorageIcon, TruckIcon } from 'Atoms/Icons';
import { H3 } from 'Atoms/Typography/Headings/Heading';
import SingleOptionCard from 'Organisms/SingleOptionCard/SingleOptionCard';
import { useEffect, useState } from 'react';
import { isEmpty } from 'Shared/Common/Helpers';
import { styled } from 'stitches.config';
import DeliveryAgentDropDown from './DeliveryAgentDropDown';
import FormSubmission from 'Models/Pages/CheckoutPage/FormSubmission.interface';
import FormSubmissionB2C from 'Models/Pages/CheckoutPage/FormSubmissionB2C.interface';
import LoadingCircle from 'Atoms/Loaders/LoadingCircle';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { useDebounce } from 'Shared/Hooks/useDebounce';
import {
  GetDeliveryMethodUsingZipcode,
  UpdateCartWithDeliveryInfo,
  RequestStatus,
  Validation,
} from './DeliveryMethodAPI';
import CheckoutPaymentMethods from '../CheckoutPaymentMethods';
import { GetCart } from 'Shared/Cart/Cart';

type PropTypes = {
  validFormData: boolean;
  addressPayload: FormSubmissionB2C;
  onValidation: (validation: Validation) => void;
};

const DeliveryMethods = ({
  validFormData,
  addressPayload,
  onValidation,
}: PropTypes) => {
  const { languageRoute } = useAppSettingsData();
  const { cart } = GetCart(languageRoute);
  const { checkoutLabels } = useTranslationData();
  const debouncedAddressPayload = useDebounce(addressPayload, 500);

  const [deliveryOptions, setDeliveryOptions] = useState<any>('');
  const [currentDeliveryOption, setCurrentDeliveryOption] =
    useState<string>('');
  const [selectedAgentId, setSelectedAgentId] = useState<string>('');
  const [serviceId, setServiceId] = useState<string>('');
  const [onState, setOnState] = useState<RequestStatus>('');

  const getCurrentDeliveryOptionObject = () => {
    if (currentDeliveryOption && deliveryOptions) {
      const currentShippingPlaces = deliveryOptions.filter((option: any) => {
        return option.shippingId === currentDeliveryOption;
      });
      if (!isEmpty(currentShippingPlaces)) {
        return currentShippingPlaces[0];
      }
    }
    return null;
  };

  const hasSelectedDeliveryOption = () => {
    if (currentDeliveryOption && selectedAgentId) {
      return true;
    } else if (currentDeliveryOption && !selectedAgentId) {
      const currentShippingPlaces = getCurrentDeliveryOptionObject();

      return isEmpty(currentShippingPlaces?.shippingDeliveryPlaces);
    }
    return false;
  };

  useEffect(() => {
    // set the initial currentDeliveryOption and selectedAgentId
    // from cart only if we havent selected any option
    if (cart) {
      if (
        cart.shippingOptionId !== currentDeliveryOption &&
        !currentDeliveryOption
      ) {
        setCurrentDeliveryOption(cart.shippingOptionId);
        setSelectedAgentId(cart.deliveryOptionId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  useEffect(() => {
    if (cart && !isEmpty(debouncedAddressPayload.shippingPostalCode)) {
      // fetch new deliveryMethods when addresspayload changes
      GetDeliveryMethodUsingZipcode(
        debouncedAddressPayload.shippingPostalCode,
        setOnState,
        setDeliveryOptions
      );
    }
  }, [debouncedAddressPayload.shippingPostalCode, cart]);

  useEffect(() => {
    if (currentDeliveryOption && deliveryOptions) {
      const tempDeliveryObj = getCurrentDeliveryOptionObject();

      if (tempDeliveryObj && isEmpty(tempDeliveryObj.shippingDeliveryPlaces)) {
        // update deliveryinfo upon site refresh if option has no shipping delivery places.
        UpdateCartWithDeliveryInfo(
          {
            ...addressPayload,
            shippingOptionId: currentDeliveryOption,
            deliveryPlaceId: '',
            serviceId: getCurrentDeliveryOptionObject().serviceId,
          } as FormSubmission,
          onValidation,
          languageRoute
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedAgentId) && !isEmpty(currentDeliveryOption)) {
      UpdateCartWithDeliveryInfo(
        {
          ...addressPayload,
          shippingOptionId: currentDeliveryOption,
          deliveryPlaceId: selectedAgentId,
          serviceId: serviceId,
        } as FormSubmission,
        onValidation,
        languageRoute
      );
    }
  }, [
    addressPayload,
    debouncedAddressPayload,
    currentDeliveryOption,
    languageRoute,
    selectedAgentId,
    serviceId,
    onValidation,
  ]);

  // Only render component if form is valid
  if (!validFormData) return <></>;

  // Render error text if getDeliveryOptions fails
  if (onState === 'error')
    return (
      <DeliveryMethodContainer>
        <H3 css={{ mt: 0 }}>{checkoutLabels.deliveryOptions}</H3>
        <ErrorText>
          {checkoutLabels.couldNotFindPostalCode}{' '}
          {addressPayload.shippingPostalCode}
        </ErrorText>
      </DeliveryMethodContainer>
    );
  if (!deliveryOptions)
    return (
      <LoadingWrapper>
        <LoadingCircle />
      </LoadingWrapper>
    );

  return (
    <>
      <DeliveryMethodContainer>
        <H3 css={{ mt: 0 }}>{checkoutLabels.deliveryOptions}</H3>
        <DeliveryOptionsWrapper>
          {deliveryOptions &&
            deliveryOptions.map((option: any, index: number) => {
              return (
                <div key={index}>
                  <SingleOptionCard
                    onClick={() => {
                      setCurrentDeliveryOption(option.shippingId);
                      setServiceId(option.serviceId);
                      if (isEmpty(option.shippingDeliveryPlaces)) {
                        setSelectedAgentId('');
                        UpdateCartWithDeliveryInfo(
                          {
                            ...addressPayload,
                            shippingOptionId: option.shippingId,
                            deliveryPlaceId: '',
                            serviceId: option.serviceId,
                          } as FormSubmission,
                          onValidation,
                          languageRoute
                        );
                      }
                    }}
                    isActive={currentDeliveryOption === option.shippingId}
                    image={
                      <DeliveryIcon>
                        {isEmpty(option.shippingDeliveryPlaces) ? (
                          <StorageIcon size="l" color="primary" />
                        ) : (
                          <TruckIcon size="l" color="primary" />
                        )}
                      </DeliveryIcon>
                    }
                    content={
                      <DeliveryContent>
                        <DeliveryHeading>
                          <DeliveryTitle>{option.name}</DeliveryTitle>
                          <DeliveryPrice>
                            {option.priceDescription}
                          </DeliveryPrice>
                        </DeliveryHeading>
                        <DeliverySubHeading>
                          {option.shippingOptionText}
                        </DeliverySubHeading>
                      </DeliveryContent>
                    }
                    radioBg="secondary"
                  />
                  {currentDeliveryOption === option.shippingId &&
                    !isEmpty(option.shippingDeliveryPlaces) && (
                      <DeliveryAgentContainer>
                        <DeliveryAgentDropDown
                          fullWidth
                          currentSelected={selectedAgentId}
                          setSelectedValue={setSelectedAgentId}
                          values={option.shippingDeliveryPlaces}
                          placeholderLabel={checkoutLabels.choosePickupPoint}
                        />
                      </DeliveryAgentContainer>
                    )}
                </div>
              );
            })}
        </DeliveryOptionsWrapper>
      </DeliveryMethodContainer>
      {hasSelectedDeliveryOption() && (
        <CheckoutPaymentMethods
          addressPayload={addressPayload}
          validFormData={validFormData}
          cart={cart}
        />
      )}
    </>
  );
};

export default DeliveryMethods;

const LoadingWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
});

const DeliveryTitle = styled('span', {
  color: '$textPrimary',
  mr: 2,
  '&:after': {
    content: ' - ',
  },
  '@mediaMinLarge': {
    width: '80%',
    '&:after': {
      content: 'none',
    },
  },
});

const DeliveryPrice = styled('span', {
  color: '$textPrimary',
  width: 'fit-content',
  '@mediaMinLarge': {
    width: '20%',
  },
});

const DeliveryMethodContainer = styled('div', {
  backgroundColor: '$primary4',
  padding: '16px',
  '@mediaMinLarge': {
    padding: '32px',
  },
});

const DeliveryOptionsWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const DeliveryContent = styled('div', {
  minH: '80px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  py: 4,
  paddingRight: '24px',
  paddingLeft: '8px',
  backgroundColor: '$primary3',
});

const DeliveryHeading = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  textAlign: 'left',
  fontFamily: '$fontPrimary500',
  ls: 'lsn1',
  color: '$secondary2',
  wordSpacing: '$wordSpacings$fontPrimary',
  fs: 8,
  lineHeight: '$lh15',
  '@mediaMinLarge': {
    fs: 9,
    lineHeight: '$lh155',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  marginLeft: '16px',
  width: '100%',
});

const DeliverySubHeading = styled('span', {
  fs: 6,
  marginLeft: '16px',
  color: '$secondary2',
  lineHeight: '$lh133',
  textAlign: 'left',
});

const DeliveryAgentContainer = styled('div', {
  marginTop: '8px',
  backgroundColor: '$primary3',
});

const DeliveryIcon = styled('div', {
  pl: 4,
  pt: 7,
  width: '70px',
  alignItems: 'flex-start',
  justifyContent: 'center',
  backgroundColor: '$primary3',
  display: 'none',
  '@mediaMinLarge': {
    display: 'flex',
  },
});

const ErrorText = styled('div', {
  fontFamily: '$fontSecondary400',
  letterSpacing: '$ls0',
  fs: 6,
  lineHeight: '$lh133',
  color: '$errorColor',
  ml: '15px',
});
