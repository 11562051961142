import { useEffect, useState } from 'react';
import Button from 'Atoms/Buttons/Button';
import { H3 } from 'Atoms/Typography/Headings/Heading';
import Paragraph from 'Atoms/Typography/Paragraph/Paragraph';
import SwitchToLoginView from 'Organisms/AccountModal/AccountRegister/SwitchToLoginView';
import SwitchToRegisterView from 'Organisms/AccountModal/AccountLogin/SwitchToRegisterView';
import BankIdRegisterForm from 'Organisms/AccountModal/AccountRegister/BankIdRegisterForm';
import BankIdLoginForm from 'Organisms/AccountModal/AccountLogin/BankIdLoginForm';
import CheckoutSection from './CheckoutSection';
import { GetCart } from 'Shared/Cart/Cart';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { useUserStateData } from 'Shared/Providers/UserContextProvider';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { ModalName } from 'Organisms/AccountModal/AccountModal';
import { styled } from 'stitches.config';
import BankIdAuthentication from 'Organisms/AccountModal/Containers/BankIdAuthentication/BankIdAuthentication';
import BankIdIdentify from 'Organisms/AccountModal/Containers/BankIdIdentify/BankIdIdentify';
import InfoIcon from 'Atoms/Icons/InfoIcon';

type PropType = {
  continueAsGuest: boolean;
  setContinueAsGuest: () => void;
};

const CheckoutAccount = ({ continueAsGuest, setContinueAsGuest }: PropType) => {
  const { accountLabels, checkoutLabels, errorLabels } = useTranslationData();
  const { languageRoute } = useAppSettingsData();
  const {
    user: { authenticated, isLoggedWithBankId },
  } = useUserStateData();
  const { cart, isCartEmpty, hasMotorCycle } = GetCart(languageRoute);

  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
  const [isAuthFailed, setIsAuthFailed] = useState<boolean>(false);
  const [userAlreadyExists, setUserAlreadyExists] = useState<boolean>(false);

  const [bankIdQrCode, setBankIdQrCode] = useState<string>('');
  const [bankIdPollStatus, setBankIdPollStatus] = useState<string>('');
  const [isOpenBankId, setIsOpenBankId] = useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<any>(0);

  const [currentView, setView] = useState<ModalName>(ModalName.LOGIN);

  useEffect(() => {
    switch (bankIdPollStatus.toLocaleLowerCase()) {
      case 'failed':
        setIsAuthFailed(true);
        setIsAuthenticating(false);
        break;
      case 'pending':
        setIsAuthenticating(true);
        break;
      case 'complete':
        setIsAuthFailed(false);
        setIsAuthenticating(false);
        break;
      default:
    }
  }, [bankIdPollStatus]);

  if (continueAsGuest || isCartEmpty) return <></>;

  const BankIdAuthAborted = () => {
    return (
      <ErrorText css={{ textAlign: 'left' }}>
        <ErrorTextWrapper>
          <InfoIcon space={true} size={'l'} color={'error'} />
          {userAlreadyExists
            ? errorLabels.errorBankIdExistingUser
            : errorLabels.bankIdAuthInterrupted}
        </ErrorTextWrapper>
      </ErrorText>
    );
  };

  // If user has logged in with email - prompt with bankid identify
  if (authenticated && hasMotorCycle && !isLoggedWithBankId) {
    return (
      <CheckoutSection>
        <AccountWrapper>
          <H3 css={{ mt: 0 }}>{accountLabels.identifyWithBankId}</H3>
          {!isAuthenticating && (
            <>
              {isAuthFailed && <BankIdAuthAborted />}
              <BankIdIdentify
                inputBackground="primary4"
                setIsAuthenticating={setIsAuthenticating}
                setBankIdQrCode={setBankIdQrCode}
                setBankIdPollStatus={setBankIdPollStatus}
                setIsOpenBankId={setIsOpenBankId}
                setIntervalId={setIntervalId}
              />
            </>
          )}
          {isAuthenticating && (
            <>
              <BankIdAuthentication
                setIsAuthenticating={setIsAuthenticating}
                setIsAuthFailed={setIsAuthFailed}
                isOpenBankId={isOpenBankId}
                bankIdQrCode={bankIdQrCode}
                isAuthenticating={isAuthenticating}
                intervalId={intervalId}
                setIntervalId={setIntervalId}
              />
            </>
          )}
        </AccountWrapper>
      </CheckoutSection>
    );
  }

  return !authenticated ? (
    <CheckoutSection>
      {currentView === ModalName.REGISTER ? (
        <AccountWrapper>
          <H3 css={{ mt: 0 }}>{accountLabels.createAccount}</H3>
          {!isAuthenticating && (
            <>
              <RegisterParagraph>
                <Paragraph>
                  {checkoutLabels.checkoutAccountRegisterBodyText}
                </Paragraph>
              </RegisterParagraph>
              {isAuthFailed && <BankIdAuthAborted />}
              <BankIdRegisterForm
                inputBackground="primary4"
                setIsAuthenticating={setIsAuthenticating}
                setBankIdQrCode={setBankIdQrCode}
                setBankIdPollStatus={setBankIdPollStatus}
                setConfirmedEmail={() => {}}
                setIsOpenBankId={setIsOpenBankId}
                setIntervalId={setIntervalId}
                setUserAlreadyExists={setUserAlreadyExists}
              />
              {!cart?.includedMotorcycle && (
                <Button type="primary2" fullWidth onClick={setContinueAsGuest}>
                  {accountLabels.continueAsGuest}
                </Button>
              )}

              <SwitchToLoginView switchView={() => setView(ModalName.LOGIN)} />
            </>
          )}
        </AccountWrapper>
      ) : (
        <AccountWrapper>
          <H3 css={{ mt: 0 }}>{accountLabels.signIn}</H3>
          {!isAuthenticating && (
            <>
              {isAuthFailed && BankIdAuthAborted()}
              <BankIdLoginForm
                inputBackground="primary4"
                setIsAuthenticating={setIsAuthenticating}
                setBankIdQrCode={setBankIdQrCode}
                setBankIdPollStatus={setBankIdPollStatus}
                setIsOpenBankId={setIsOpenBankId}
                setIntervalId={setIntervalId}
              />
              {!cart?.includedMotorcycle && (
                <Button
                  css={{ marginBottom: '24px' }}
                  type="primary2"
                  fullWidth
                  onClick={setContinueAsGuest}
                >
                  {accountLabels.continueAsGuest}
                </Button>
              )}
              <SwitchToRegisterView
                switchView={() => setView(ModalName.REGISTER)}
              />
            </>
          )}
        </AccountWrapper>
      )}
      <>
        {isAuthenticating && (
          <BankIdAuthentication
            setIsAuthenticating={setIsAuthenticating}
            setIsAuthFailed={setIsAuthFailed}
            isOpenBankId={isOpenBankId}
            bankIdQrCode={bankIdQrCode}
            isAuthenticating={isAuthenticating}
            intervalId={intervalId}
            setIntervalId={setIntervalId}
          />
        )}
      </>
    </CheckoutSection>
  ) : (
    <></>
  );
};

const fontStyleCss = {
  fontFamily: '$fontSecondary400',
  letterSpacing: '$ls0',
  lineHeight: '$lh125',
  color: '$secondary2',
  fs: 8,
};

const AccountWrapper = styled('div', {
  maxWidth: '495px',
});

const RegisterParagraph = styled('div', {
  mb: 8,
});

const ErrorText = styled('div', {
  ...fontStyleCss,
  fs: 7,
  lineHeight: '$lh133',
  color: '$errorColor',
  textAlign: 'center',
  mt: 10,
});

const ErrorTextWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '16px',
  gap: '0',
});

export default CheckoutAccount;
