import { styled } from 'stitches.config';
import { useEffect, useState } from 'react';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';

import Button from 'Atoms/Buttons/Button';
import SingleOptionCard from 'Organisms/SingleOptionCard/SingleOptionCard';
import KlarnaHelpers from '../../../Kex/PaymentProviders/KlarnaHelpers';

import FormSubmissionB2C from 'Models/Pages/CheckoutPage/FormSubmissionB2C.interface';
import { useKexNavigate } from 'Kex/KexRouter/KexRouter';

import { PaymentHeading, PaymentContent, PaymentIcon } from './PayExPayment';
import Paragraph from 'Atoms/Typography/Paragraph/Paragraph';
import { InfoIcon } from 'Atoms/Icons';
import KexCartModel from 'Models/Cart/KexCartModel.interface';
import { KlarnaTyped } from '../../../Models/Payment/PaymentMethodModelTyped';

type PropTypes = {
  addressPayload: FormSubmissionB2C;
  currentPaymentMethod: string;
  onChangePaymentMethod: (paymentType: string) => Promise<void>;
  cart: KexCartModel;
  setErrorTermsConditions: (value: boolean) => void;
  acceptedTermsConditions: boolean;
  klarnaPaymentList: KlarnaTyped[];
};

function KlarnaPayment({
  addressPayload,
  currentPaymentMethod,
  onChangePaymentMethod,
  cart,
  setErrorTermsConditions,
  acceptedTermsConditions,
  klarnaPaymentList,
}: PropTypes) {
  const [errorCreateOrder, setErrorCreateOrder] = useState<string>('');

  const {
    languageRoute,
    staticPages: { checkoutPage: apiUrl },
    marketCountryThreeLetterCountryCode,
  } = useAppSettingsData();
  const {
    checkoutLabels: { orderError, purchase },
  } = useTranslationData();

  const kexNavigate = useKexNavigate();

  async function loadKlarnaWidget(paymentType: string) {
    if (!acceptedTermsConditions) {
      setErrorTermsConditions(true);

      return;
    }

    await onChangePaymentMethod(paymentType);

    KlarnaHelpers.loadWidget(paymentType, addressPayload, languageRoute);
  }

  function authorizeKlarnaWidget() {
    KlarnaHelpers.authorize(
      currentPaymentMethod,
      addressPayload,
      successCallback,
      errorCallback,
      apiUrl,
      languageRoute,
      marketCountryThreeLetterCountryCode
    );
  }

  useEffect(() => {
    onChangePaymentMethod('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, addressPayload]);

  function successCallback(url: string) {
    kexNavigate(url);
  }

  function errorCallback() {
    setErrorCreateOrder(orderError);
  }

  return (
    <>
      {klarnaPaymentList?.map((payment, index) => {
        return (
          <div key={index}>
            <SingleOptionCard
              onClick={() => loadKlarnaWidget(payment.identifier)}
              isActive={currentPaymentMethod === payment.identifier}
              image={
                <PaymentIcon>
                  <img src={payment.assetUrls} alt="" />
                </PaymentIcon>
              }
              content={
                <PaymentContent>
                  <PaymentHeading>{payment.name}</PaymentHeading>
                </PaymentContent>
              }
              radioBg="secondary"
            />
            {currentPaymentMethod === payment.identifier && (
              <>
                <KlarnaWidgetContainer
                  id={`klarna-payments-${payment.identifier}`}
                />
                {errorCreateOrder !== '' && (
                  <ErrorContainer>
                    <InfoIcon color="error" size="m" />
                    <Paragraph>{errorCreateOrder}</Paragraph>
                  </ErrorContainer>
                )}
                <Button
                  onClick={authorizeKlarnaWidget}
                  fullWidth
                  disabled={currentPaymentMethod === ''}
                >
                  {purchase}
                </Button>
              </>
            )}
          </div>
        );
      })}
    </>
  );
}

const KlarnaWidgetContainer = styled('div', {
  p: 8,
  mb: 4,
  backgroundColor: '#F0EEEB',
});

const ErrorContainer = styled('div', {
  display: 'flex',
  gap: 8,
  mb: 4,
});

export default KlarnaPayment;
