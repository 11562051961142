import { useKexNavigate } from 'Kex/KexRouter/KexRouter';
import FormSubmissionB2C from 'Models/Pages/CheckoutPage/FormSubmissionB2C.interface';
import PaymentResultModel from 'Models/Pages/CheckoutPage/PaymentResultModel.interface';
import { EmptyCart } from 'Shared/Cart/Cart';

import {
  InitPaymentType,
  PaymentMethods,
} from 'Pages/Checkout/PaymentProviders';
import { useCallback, useRef } from 'react';
import { RequestStatus } from 'Pages/AccountPage/Account';

type PayExUrlResponse = {
  url: string;
};

type PayExCompletedEventType = {
  id: string;
};

const payExStyling = {
  body: {
    backgroundColor: '#1E1F24',
  },
  input: {
    focus: {
      border: 'solid 1px #00C087',
    },
    backgroundColor: '#1E1F24',
    border: 'solid 1px #B4B4BB',
    color: '#F0F0F1',
  },
  label: {
    color: '#F0F0F1',
    backgroundColor: '#1E1F24',
  },
  link: {
    color: '#F0F0F1',
  },
  button: {
    backgroundColor: '#00C087',
    color: '#292A31',
    font: '$fontPrimary500',
  },
  secondaryButton: {
    fontFamily: '$fontPrimary500',
    color: '#F0F0F1',
    border: 'solid 1px #F0F0F1',
  },
};

export default function PayEx(
  languageRoute: string,
  initPayment: (data: InitPaymentType) => void,
  createOrder: (data: FormSubmissionB2C, endpoint: string) => Promise<Response>,
  verifyOrder: (paymentId: string, endpoint: string) => Promise<Response>,
  setOnState: (state: RequestStatus) => void
) {
  const kexNavigate = useKexNavigate();

  const onCompleteQueue = useRef<Array<PayExCompletedEventType>>([]);

  const verifyPayEx = useCallback(
    (languageRoute: string, paymentId?: string) => {
      verifyOrder(paymentId ? paymentId : '', 'VerifyPayExPayment')
        .then((res: Response) => {
          if (!res.ok) {
            throw Error('Could not fetch data from resource');
          }
          return res.json();
        })
        .then((data: PaymentResultModel) => {
          if (data.code === 200 && data.purchaseOrderId !== -1) {
            setOnState('success');
            EmptyCart(languageRoute);
            kexNavigate(data.redirectUrl);
          }
        })
        .catch((err) => {
          setOnState('error');
          console.error(err);
        });
    },
    [kexNavigate, verifyOrder, setOnState]
  );

  const initPayEx = useCallback(() => {
    (window as any).payex.hostedView
      .creditCard({
        container: PaymentMethods.PayEx,
        // Finalize the transaction
        onPaymentCompleted: (
          paymentCompletedEvent: PayExCompletedEventType
        ) => {
          if (onCompleteQueue.current.indexOf(paymentCompletedEvent) >= 0) {
            return;
          }
          onCompleteQueue.current.push(paymentCompletedEvent);
          verifyPayEx(languageRoute, paymentCompletedEvent.id);
        },
        onPaymentFailed: (paymentFailedEvent: Error) => {
          console.error('paymentFailedEvent', paymentFailedEvent);
        },
        onError: (error: Error) => {
          console.error('error', error);
        },
        style: {
          ...payExStyling,
        },
      })
      .open();
  }, [languageRoute, onCompleteQueue, verifyPayEx]);

  const openPayEx = useCallback(
    async (data: FormSubmissionB2C, container: string) => {
      const args = {
        createOrder,
        verifyOrder,
        data,
        container,
      };
      const res = await createOrder(data, 'GetPayExUrl');
      if (res.status === 200) {
        const payExUrlResponse = res
          ? await res.json()
          : ({} as PayExUrlResponse);

        if (payExUrlResponse.url) {
          initPayment({
            id: 'payex-script',
            src: payExUrlResponse.url,
            onLoad: initPayEx,
            args,
          });
        }
      }
    },
    [createOrder, initPayEx, initPayment, verifyOrder]
  );

  return { openPayEx, verifyPayEx };
}
