import FormSubmissionB2C from 'Models/Pages/CheckoutPage/FormSubmissionB2C.interface';

const klarnaWidgetStyling = {
  color_border: '#787885',
  color_border_selected: '#00C087',
  color_details: '#00C087',
  color_text: '#0E0E10',
  radius_border: '5px',
};

declare var Klarna: any;

type KlarnaResponseType = {
  authorization_token: string;
  show_form: boolean;
  approved: boolean;
  finalize_required: boolean;
};

const KlarnaPayment = {
  getTransformedKlarnaPayload: async function getTransformedPayload(
    addressPayload: FormSubmissionB2C,
    languageRoute: string
  ) {
    const res = await fetch(
      `/KlarnaPayments/GetOrderRequest?language=` + languageRoute,
      {
        method: 'GET',
      }
    );

    const orderPayload = await res.json();

    // TODO: sameAsShippingAddress should be converted to boolean

    const sameAsShippingAddress =
      addressPayload.sameAsShippingAddress.toLowerCase() === 'true'
        ? {
            given_name: addressPayload.shippingName,
            family_name: addressPayload.shippingLastName,
            email: addressPayload.email,
            street_address: addressPayload.shippingAddress,
            postal_code: addressPayload.shippingPostalCode,
            city: addressPayload.shippingCity,
            phone: addressPayload.phoneNumber,
            country: orderPayload['purchase_country'],
          }
        : {
            given_name: addressPayload.billingName,
            family_name: addressPayload.billingLastName,
            email: addressPayload.email,
            street_address: addressPayload.billingAddress,
            postal_code: addressPayload.billingPostalCode,
            city: addressPayload.billingCity,
            phone: addressPayload.phoneNumber,
            country: orderPayload['purchase_country'],
          };

    return {
      purchase_country: orderPayload['purchase_country'],
      purchase_currency: orderPayload['purchase_currency'],
      locale: orderPayload['locale'],
      billing_address: {
        ...sameAsShippingAddress,
      },
      shipping_address: {
        given_name: addressPayload.shippingName,
        family_name: addressPayload.shippingLastName,
        email: addressPayload.email,
        street_address: addressPayload.shippingAddress,
        postal_code: addressPayload.shippingPostalCode,
        city: addressPayload.shippingCity,
        phone: addressPayload.phoneNumber,
        country: orderPayload['purchase_country'],
      },
      order_amount: orderPayload['order_amount'],
      order_tax_amount: orderPayload['order_tax_amount'],
      order_lines: orderPayload['order_lines'],
      customer: {
        national_identification_number: addressPayload.personalNumber,
      },
    };
  },
  initSDK: function initSDK(clientToken: string) {
    if (!clientToken) return;

    Klarna.Payments.init({
      client_token: clientToken,
    });
  },
  loadWidget: async function loadWidget(
    paymentType: string,
    addressPayload: FormSubmissionB2C,
    languageRoute: string
  ) {
    const klarnaPayload = await KlarnaPayment.getTransformedKlarnaPayload(
      addressPayload,
      languageRoute
    );

    Klarna.Payments.load(
      {
        container: `#klarna-payments-${paymentType}`,
        payment_method_category: paymentType,
      },
      {
        ...klarnaPayload,
        options: klarnaWidgetStyling,
      },
      function (res: KlarnaResponseType) {}
    );
  },
  createOrder: async function createOrder(
    klarnaAuthToken: string,
    addressPayload: FormSubmissionB2C,
    successCallback: (url: string) => void,
    errorCallback: () => void,
    apiUrl: string,
    threeLetterMarketCountryCode: string
  ) {
    addressPayload.billingCountry = threeLetterMarketCountryCode;
    addressPayload.shippingCountry = threeLetterMarketCountryCode;

    const res = await fetch(`${apiUrl}CreateKlarnaOrder`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        authorizationToken: klarnaAuthToken,
        ...addressPayload,
      }),
    });

    if (res.ok) {
      const { redirectUrl, code } = await res.json();

      if (code === 200) {
        successCallback(redirectUrl);
      } else {
        errorCallback();
      }
    } else {
      errorCallback();
    }
  },
  authorize: async function authorize(
    paymentType: string,
    addressPayload: FormSubmissionB2C,
    successCallback: (url: string) => void,
    errorCallback: () => void,
    apiUrl: string,
    languageRoute: string,
    threeLetterMarketCountryCode: string
  ) {
    Klarna.Payments.authorize(
      {
        payment_method_category: paymentType,
      },
      {
        ...(await KlarnaPayment.getTransformedKlarnaPayload(
          addressPayload,
          languageRoute
        )),
      },
      function (res: KlarnaResponseType) {
        if (res.approved) {
          KlarnaPayment.createOrder(
            res['authorization_token'],
            addressPayload,
            successCallback,
            errorCallback,
            apiUrl,
            threeLetterMarketCountryCode
          );
        }
      }
    );
  },
};

export default KlarnaPayment;
