import { useEffect, useReducer, useState } from 'react';
import Button from '../../../Atoms/Buttons/Button';
import { FinanceIcon, InfoIcon } from '../../../Atoms/Icons';
import Slider from '../../../Atoms/Slider/Slider';
import {
  PaymentTyped,
  SantanderTyped,
} from '../../../Models/Payment/PaymentMethodModelTyped';
import BankidIcon from '../../../Atoms/Icons/BankidIcon';
import SingleOptionCard from '../../../Organisms/SingleOptionCard/SingleOptionCard';
import { useAppSettingsData } from '../../../Shared/Providers/AppSettingsProvider';
import { PaymentMethods } from '../PaymentProviders';
import { PaymentIcon } from './PayExPayment';
import { santanderReducer } from './SantanderReducer';
import FormSubmissionB2C from '../../../Models/Pages/CheckoutPage/FormSubmissionB2C.interface';
import SantanderDownPaymentModel from '../../../Models/Payment/SantanderDownPaymentModel.interface';
import { useDebounce } from '../../../Shared/Hooks/useDebounce';
import { styled } from '../../../stitches.config';
import { H3 } from 'Atoms/Typography/Headings/Heading';
import PriceModel from 'Models/Price/PriceModel.interface';

type Props = {
  currentPaymentMethod: string;
  santander: SantanderTyped;
  addressPayload: FormSubmissionB2C;
  total: number;
  subTotal: number;
  isTradeIn: boolean;
  termsAccepted: boolean;
  setErrorTermsAccepted: () => void;
  onChangePaymentMethod: (paymentType: string) => Promise<void>;
  tradeInDiscount: PriceModel;
};

function SantanderPayment({
  currentPaymentMethod,
  santander,
  addressPayload,
  onChangePaymentMethod,
  setErrorTermsAccepted,
  total,
  subTotal,
  isTradeIn,
  termsAccepted,
  tradeInDiscount,
}: Props) {
  const {
    translations: {
      checkoutLabels: { santanderHeader, santanderSubheader },
    },
    languageRoute,
  } = useAppSettingsData();
  const active =
    currentPaymentMethod === PaymentMethods.Santander && termsAccepted;

  return (
    <>
      <SingleOptionCard
        onClick={async () => {
          if (!termsAccepted) {
            setErrorTermsAccepted();
          } else {
            await onChangePaymentMethod(PaymentMethods.Santander);
          }
        }}
        isActive={active}
        image={
          <PaymentIcon>
            <FinanceIcon size="l" color="primary" />
          </PaymentIcon>
        }
        content={
          <PaymentContent>
            <PaymentHeading>{santanderHeader}</PaymentHeading>
            <PaymentSubHeading>{santanderSubheader}</PaymentSubHeading>
          </PaymentContent>
        }
        radioBg="secondary"
      />

      {active && (
        <SantanderPaymentComponent
          initialSantander={santander}
          addressPayload={addressPayload}
          total={total}
          subTotal={subTotal}
          isTradeIn={isTradeIn}
          termsAccepted={termsAccepted}
          setErrorTermsAccepted={setErrorTermsAccepted}
          tradeInDiscount={tradeInDiscount}
          languageRoute={languageRoute}
        />
      )}
    </>
  );
}

const formatPaymentDetails = (
  templateString: string,
  santander: SantanderTyped,
  numberOfMonths: number
) => {
  const keys = {
    rent: santander.interestRateFrom,
    effectiveRent: santander.effectiveInterestRateFrom,
    monthlyCost: santander.monthlyPayment.priceRoundedAsString,
    loanAmount: santander.financedAmount,
    numberOfMonths: `${numberOfMonths}`,
    loanTotalAmount: santander.totalPayment,
  };

  let output = templateString;

  for (const [key, value] of Object.entries(keys)) {
    output = output.replaceAll(`{${key}}`, value);
  }

  return output;
};

function SantanderPaymentComponent({
  initialSantander,
  addressPayload,
  total,
  subTotal,
  termsAccepted,
  isTradeIn,
  setErrorTermsAccepted,
  tradeInDiscount,
  languageRoute,
}: {
  initialSantander: SantanderTyped;
  addressPayload: FormSubmissionB2C;
  total: number;
  subTotal: number;
  isTradeIn: boolean;
  termsAccepted: boolean;
  setErrorTermsAccepted: () => void;
  tradeInDiscount: PriceModel;
  languageRoute: string;
}) {
  const minimumTermsOfMonth = 12;
  const maximumTermsOfMonth = 72;
  const termsOfMonthIncremmentValue = 12;

  const [errorMessage, setErrorMessage] = useState('');
  const [santanderModel, setSantanderModel] = useState(initialSantander);

  const [downPayment, dispatch] = useReducer(
    santanderReducer(
      initialSantander.downPaymentMin,
      initialSantander.downPaymentMax
    ),
    {
      value: initialSantander.downPaymentMin,
      inputText: initialSantander.downPaymentMin.toString(),
      valid: true,
    }
  );

  const [termsInMonth, setTermsInMonth] = useState(minimumTermsOfMonth);
  const [calculatingLoan, setCalculatingFinancialLoan] = useState(false);
  const [processingPayment, setProcessPayment] = useState(false);

  const debouncedDownPayment = useDebounce(downPayment, 500);
  const debouncedTermsInMonth = useDebounce(termsInMonth, 500);

  useEffect(() => {
    const isDebouncing =
      (debouncedDownPayment.valid &&
        downPayment.value !== debouncedDownPayment.value) ||
      termsInMonth !== debouncedTermsInMonth;

    if (isDebouncing) {
      setCalculatingFinancialLoan(true);
    }
  }, [
    downPayment.value,
    termsInMonth,
    debouncedDownPayment.value,
    debouncedDownPayment.valid,
    debouncedTermsInMonth,
  ]);

  const {
    staticPages: { checkoutPage },
    currency,
    translations: {
      checkoutLabels: {
        santanderButtonText,
        santanderDownPayment,
        santanderNumberOfMonths,
        santanderPaymentDetails,
        santanderMonthlyCost,
        santanderPaymentFailed,
        santanderNotApproved,
        santanderTooSmallDownpayment,
        tradeInDiscountAmount,
        tradeInDownPaymentAfterDiscount,
      },
    },
  } = useAppSettingsData();

  useEffect(() => {
    let isFetchPayments = true;

    if (!debouncedDownPayment.valid) {
      return;
    }

    const calculateModel: SantanderDownPaymentModel = {
      downPayment: debouncedDownPayment.value,
      termsInMonth: debouncedTermsInMonth,
    };

    if (isFetchPayments) {
      fetch(`${checkoutPage}PaymentMethods`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(calculateModel),
      })
        .then((res) => res.json())
        .then((value: PaymentTyped[]) => {
          const newModel = value.find(
            (a: PaymentTyped): a is SantanderTyped =>
              a.paymentMethodModelType === 'SantanderPaymentModel'
          );

          if (!newModel) {
            console.error(value);
            throw Error('Could not calculate finance');
          }

          setSantanderModel(newModel);
        })
        .finally(() => setCalculatingFinancialLoan(false));
    }

    return () => {
      isFetchPayments = false;
    };
  }, [
    debouncedDownPayment.value,
    debouncedDownPayment.valid,
    debouncedTermsInMonth,
    setCalculatingFinancialLoan,
    setSantanderModel,
    checkoutPage,
    total,
  ]);

  const processPayment = async () => {
    if (!termsAccepted) {
      setErrorTermsAccepted();
      return;
    }
    setProcessPayment(true);

    const response = await fetch(
      `${checkoutPage}CreateSantanderFinancingOrder`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...addressPayload,
          downPayment: downPayment.value,
          termsInMonth,
        }),
      }
    );

    try {
      const { redirectUrl, financingApproved } = await response.json();

      if (financingApproved) {
        window.location.href = redirectUrl;
        setErrorMessage('');
      } else {
        setErrorMessage(santanderNotApproved);
      }
    } catch (e) {
      console.error(e);
      setErrorMessage(santanderPaymentFailed);
    } finally {
      setProcessPayment(false);
    }
  };

  return (
    <Container>
      <Left>
        {santanderModel?.campaignName && <H3>{santanderModel.campaignName}</H3>}
        <DownPaymentWrapper>
          <DownPaymentHeader>{santanderDownPayment}</DownPaymentHeader>
          <DownPaymentCostWrapper>
            <DownPaymentCost
              value={downPayment.inputText}
              onBlur={(el) => dispatch(Number.parseInt(el.currentTarget.value))}
              onChange={(el) => dispatch(el.currentTarget.value)}
              type="text"
            />
            <Currency>{currency}</Currency>
          </DownPaymentCostWrapper>
          {!downPayment.valid && (
            <SmallErrorText>
              {santanderTooSmallDownpayment} {initialSantander.downPaymentMin}
            </SmallErrorText>
          )}
        </DownPaymentWrapper>
        <Slider
          min={initialSantander.downPaymentMin}
          max={(initialSantander.downPaymentMax / 1_000) * 1_000}
          step={1_000}
          value={downPayment.value}
          controlled={true}
          onNewValue={dispatch}
          error={!downPayment.valid}
        />
        <MonthsHeader>
          {santanderNumberOfMonths}: {termsInMonth}
        </MonthsHeader>
        <Slider
          min={minimumTermsOfMonth}
          max={maximumTermsOfMonth}
          step={termsOfMonthIncremmentValue}
          value={termsInMonth}
          controlled={false}
          onNewValue={setTermsInMonth}
          error={false}
        />
        {isTradeIn && (
          <TradeIn>
            <TradeInColumn isDiscount>
              <TradeInTextLeft>{tradeInDiscountAmount}: </TradeInTextLeft>
              <TradeInTextRight>
                {tradeInDiscount.priceRoundedAsString}
              </TradeInTextRight>
            </TradeInColumn>
            <TradeInColumn>
              <TradeInTextLeft>
                {tradeInDownPaymentAfterDiscount}:{' '}
              </TradeInTextLeft>
              <TradeInTextRight>
                {(downPayment.value - tradeInDiscount.price).toLocaleString(
                  languageRoute
                )}{' '}
                {currency}
              </TradeInTextRight>
            </TradeInColumn>
          </TradeIn>
        )}
      </Left>
      <Right>
        <MonthlyCostHeader>{santanderMonthlyCost}</MonthlyCostHeader>
        <MonthlyCost textColorVariants={calculatingLoan}>
          {santanderModel.monthlyPayment.priceRoundedAsString}
        </MonthlyCost>
        <LendingDetails textColorVariants={calculatingLoan}>
          {formatPaymentDetails(
            santanderPaymentDetails,
            santanderModel,
            termsInMonth
          )}
        </LendingDetails>
        {!!errorMessage && (
          <ErrorContainer>
            <InfoIcon color="error" size="m" />
            <ErrorText>{errorMessage}</ErrorText>
          </ErrorContainer>
        )}
        <Button
          fullWidth
          type="primary"
          onClick={processPayment}
          isLoading={calculatingLoan || processingPayment}
          disabled={calculatingLoan || processingPayment}
        >
          <BankidIcon
            css={{ marginRight: '16px' }}
            fillColor="secondaryDark"
            size="l"
          />
          {santanderButtonText}
        </Button>
      </Right>
    </Container>
  );
}

const ErrorContainer = styled('div', {
  display: 'flex',
  color: '$errorColor',
  marginBottom: '24px',
});

const ErrorText = styled('div', {
  fs: 6,
  lineHeight: '$lh133',
  color: '$errorColor',
  marginLeft: '15px',
});

const Container = styled('div', {
  color: '$secondary2',
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: '44px',
  pl: 8,
  pr: 8,
  '@mediaMaxHuge': {
    gridTemplateColumns: '100%',
  },
});

const Currency = styled('span', {
  position: 'absolute',
  top: 12,
  right: 16,
});

const LendingDetails = styled('p', {
  fs: 6,
  mb: 12,
  whiteSpace: 'break-spaces',
  variants: {
    textColorVariants: {
      false: {
        color: '$secondary2',
      },
      true: {
        color: '$primary2',
      },
    },
  },
});

const Left = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const Right = styled('div', {
  '@mediaMaxHuge': {
    mt: 8,
  },
});

const DownPaymentWrapper = styled('div', {
  mb: 10,
  '@mediaMinMedium': {
    display: 'grid',
    gridTemplateColumns: 'auto auto 1fr auto',
    alignItems: 'center',
  },
});

const DownPaymentHeader = styled('h2', {
  pt: 0,
  pb: 0,
  lineHeight: '24px',
  fs: 9,
  mr: 7,
});

const MonthsHeader = styled('h2', {
  paddingTop: '0',
  paddingBottom: '0',
  mt: 10,
  lineHeight: '24px',
  fs: 9,
  mb: 7.5,
});

const MonthlyCostHeader = styled('h2', {
  fs: 9,
  textAlign: 'center',
});

const DownPaymentCostWrapper = styled('div', {
  outline: '1px solid $primaryLight4',
  h: 12,
  p: '12px 16px',
  fs: 8,
  display: 'grid',
  position: 'relative',
  gridTemplateColumns: 'auto',
  gridColumn: '4',
});

const DownPaymentCost = styled('input', {
  appearance: 'none',
  outline: 'none',
  background: 'transparent',
  color: '$secondary2',
  '&:focus': {
    outline: 'none',
  },
});

export const PaymentContent = styled('div', {
  minH: '80px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  py: 4,
  px: 6,
  backgroundColor: '$primary3',
});

export const PaymentHeading = styled('span', {
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  fontFamily: '$fontPrimary500',
  ls: 'lsn1',
  color: '$secondary2',
  wordSpacing: '$wordSpacings$fontPrimary',
  fs: 8,
  lineHeight: '$lh15',
  '@mediaMinLarge': {
    fs: 9,
    lineHeight: '$lh155',
  },
});

const PaymentSubHeading = styled('span', {
  fs: 6,
  color: '$secondary2',
  lineHeight: '$lh133',
  textAlign: 'left',
});

const MonthlyCost = styled('p', {
  fs: 8,
  marginTop: '0px',
  fontFamily: '$fontSecondary600',
  textAlign: 'center',
  marginBottom: '32px',
  variants: {
    textColorVariants: {
      false: {
        color: '$secondary2',
      },
      true: {
        color: '$primary2',
      },
    },
  },
});

const SmallErrorText = styled('p', {
  color: '$errorText',
  pt: 4,
  fs: 5,
});

const TradeIn = styled('div', {
  mt: 16,
});

const TradeInColumn = styled('div', {
  fs: 9,
  lineHeight: '$lh133',
  display: 'flex',
  justifyContent: 'space-between',
  gap: 16,
  mb: 3,
  '&:last-child': {
    mb: 0,
  },
  variants: {
    isDiscount: {
      true: {
        color: '$JE68GreenPrimary',
      },
    },
  },
});

const TradeInTextLeft = styled('span', {
  flex: '0 1 50%',
});

const TradeInTextRight = styled('span', {
  flex: '0 1 50%',
  textAlign: 'right',
});

export default SantanderPayment;
