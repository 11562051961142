import CloseButton from 'Atoms/Buttons/CloseButton';
import { useCallback, useEffect, useState } from 'react';
import Button from '../../Atoms/Buttons/Button';
import { InfoIcon } from '../../Atoms/Icons';
import Input from '../../Atoms/Input/Input';
import Paragraph from '../../Atoms/Typography/Paragraph/Paragraph';
import DiscountModel from '../../Models/Price/DiscountModel.interface';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { styled } from '../../stitches.config';

type Props = {
  discount?: DiscountModel;
  revalidateCart: () => void;
};

const Error = ({
  current,
  lastValid,
  update,
}: {
  current: string;
  lastValid?: string;
  update: () => void;
}) => {
  const { validationLabels, checkoutLabels } = useTranslationData();
  if (lastValid && lastValid !== current) {
    return (
      <ErrorWrapper>
        <ErrorText>{validationLabels.invalidDiscount}. </ErrorText>
        <ClickableText onClick={update}>
          {checkoutLabels.discountRevert.replace('{0}', lastValid)}
        </ClickableText>
      </ErrorWrapper>
    );
  }

  return <ErrorText>{validationLabels.invalidDiscount}</ErrorText>;
};

export const Discount = ({ discount, revalidateCart }: Props) => {
  const existingCartDiscount = discount?.couponCode;
  const existingRewardName = discount?.rewardName;
  const [triggerInput, setTriggerInput] = useState(0);
  const [showMore, setShowMore] = useState(!!discount);
  const { checkoutLabels } = useTranslationData();
  const [discountCode, setDiscountCode] = useState(existingRewardName || '');
  const [state, setState] = useState<'init' | 'loading' | 'error' | 'success'>(
    'init'
  );

  const [lastValidCode, setLastValidCode] = useState(existingCartDiscount);

  useEffect(() => {
    if (existingCartDiscount) {
      setLastValidCode(existingCartDiscount);
    }
  }, [existingCartDiscount]);

  useEffect(() => {
    setShowMore((s) => s || !!existingCartDiscount);
  }, [existingCartDiscount]);

  useEffect(
    () => setDiscountCode((s) => existingCartDiscount || s),
    [existingCartDiscount]
  );

  const newDiscount =
    !!existingCartDiscount && !existingCartDiscount.startsWith(discountCode);

  const codeApplied = existingCartDiscount === discountCode;

  const applyCode = (code: string) => {
    if (code === '') {
      setShowMore(false);
      setDiscountCode('');
    }

    setState('loading');
    fetch(`SetDiscountCode?discountCode=${code.toLocaleUpperCase()}`, {
      method: 'POST',
    })
      .then((res) => res.json())
      .then((wasRewardValid) => {
        setState(wasRewardValid ? 'success' : code === '' ? 'init' : 'error');
        revalidateCart();
      })
      .catch((err) => {
        console.error(err);
        setState('error');
      });
  };

  const setDiscountCodeManually = useCallback(
    (discount: string) => {
      setDiscountCode(discount);
      setState('init');
    },
    [setDiscountCode, setState]
  );

  return (
    <Container>
      {showMore ? (
        <>
          {newDiscount && (
            <WarningWrapper>
              <InfoIcon color="secondary" />
              <Paragraph css={{ ml: 2, fs: 7 }}>
                {checkoutLabels.discountOverwriteWarning}
              </Paragraph>
            </WarningWrapper>
          )}

          <CouponCodeWrapper hasCodeApplied={codeApplied}>
            <InputWrapper>
              <Input
                title={checkoutLabels.discountName}
                onChange={setDiscountCodeManually}
                name="Discount CouponCode"
                defaultValue={discountCode}
                key={`discountInput${triggerInput}`}
                upperCase={true}
                inputBackground="primary4"
                disabled={codeApplied}
                inputTextColor={codeApplied ? 'secondary' : 'primary'}
                showLabel={codeApplied ? false : true}
              />
              {codeApplied && (
                <CloseButton
                  css={{ wh: 2, position: 'absolute', top: 24, right: 24 }}
                  onClick={() => applyCode('')}
                  bgColor="primaryLight"
                  size="l"
                />
              )}
            </InputWrapper>
            {!codeApplied && (
              <Button
                css={{ h: 11, mt: 2 }}
                type="secondary"
                disabled={
                  (discountCode.length < 2 && state === 'init') || codeApplied
                }
                onClick={() => applyCode(discountCode)}
                isLoading={state === 'loading'}
              >
                {checkoutLabels.discountButton}
              </Button>
            )}
          </CouponCodeWrapper>
          {state === 'error' && (
            <Error
              current={discountCode}
              lastValid={lastValidCode}
              update={() => {
                if (lastValidCode) {
                  setDiscountCode(lastValidCode);
                  applyCode(lastValidCode);
                  setTriggerInput((s) => s + 1);
                }
              }}
            />
          )}
          {/* {codeApplied && state !== 'error' && (
            <SuccessText>{checkoutLabels.discountSuccess}</SuccessText>
          )} */}
        </>
      ) : (
        <DiscountExpander onClick={() => setShowMore(true)}>
          {checkoutLabels.discountTitle}
        </DiscountExpander>
      )}
    </Container>
  );
};

const DiscountExpander = styled('span', {
  textDecoration: 'underline',
  color: '$primaryLight1',
  pt: 2,
  '&:hover': {
    cursor: 'pointer',
  },
});

const ClickableText = styled('span', {
  textDecoration: 'underline',
  color: '$primaryLight2',
  fs: 7,
  lineHeight: '$lh133',
  mt: 0,
  '&:hover': {
    cursor: 'pointer',
    color: '$primaryLight1',
  },
  '@mediaMinMedium': {
    pl: 2,
  },
});

const WarningWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  mb: 4,
});

const ErrorWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  '@mediaMinMedium': {
    flexDirection: 'row',
  },
});

const ErrorText = styled('div', {
  fontFamily: '$fontSecondary400',
  letterSpacing: '$ls0',
  fs: 7,
  lineHeight: '$lh133',
  color: '$errorColor',
  textAlign: 'left',
  mt: 0,
});

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  background: '$checkoutPageBackgroundSecondary',
  mt: 4,
});

const InputWrapper = styled('div', {
  position: 'relative',
  variants: {
    true: {
      '& input': {
        color: 'red!important',
        WebkitTextFillColor: 'red!important',
        caretColor: 'red!important',
      },
    },
  },
});

const CouponCodeWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '100%',
  gridRowGap: '8px',
  gridColumnGap: '16px',
  mb: 2,
  '@mediaMinMedium': {
    gridTemplateColumns: '1fr auto',
  },
  variants: {
    hasCodeApplied: {
      true: {
        gridTemplateColumns: '100%',
      },
    },
  },
});
