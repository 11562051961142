import { styled } from '@stitches/react';
import { useCallback, useMemo } from 'react';

type Props = {
  min?: number;
  max: number;
  value: number;
  controlled: boolean;
  step: number;
  error: boolean;
  onNewValue: (value: number) => void;
};

function Slider({
  min = 0,
  max,
  step,
  value,
  controlled,
  error,
  onNewValue,
}: Props) {
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number.parseInt(e.currentTarget.value);
      onNewValue(value);
    },
    [onNewValue]
  );

  const InputRange = useMemo(() => getInputRange(error), [error]);

  if (!controlled) {
    return (
      <InputRange
        type="range"
        defaultValue={value}
        min={min}
        max={max}
        step={step}
        onChange={onChange}
      />
    );
  }

  return (
    <InputRange
      type="range"
      value={value}
      min={min}
      max={max}
      step={step}
      onChange={onChange}
    />
  );
}

const getInputRange = (error: boolean) =>
  styled('input', {
    '-webkit-appearance': 'none',
    background: '$primaryLight3',
    height: '4px',
    width: '100%',

    '&::-webkit-slider-thumb': {
      '-webkit-appearance': 'none',
      border: 'none',
      height: '16px',
      width: '16px',
      'border-radius': '50%',
      background: error ? '$errorColor' : '$secondary1',
    },
  });

export default Slider;
