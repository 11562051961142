import PayEx from 'Kex/PaymentProviders/PayEx';
import FormSubmissionB2C from 'Models/Pages/CheckoutPage/FormSubmissionB2C.interface';
import { useCallback } from 'react';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { RequestStatus } from './DeliveryMethods/DeliveryMethodAPI';

export enum PaymentMethods {
  PayEx = 'PayEx',
  Santander = 'Santander',
  Empty = '',
}

export type InitPaymentType = {
  id: string;
  src: string;
  onLoad: ({ createOrder, verifyOrder, data }: OnLoadType) => void;
  args: OnLoadType;
};

export type OnLoadType = {
  createOrder: (data: FormSubmissionB2C, endpoint: string) => Promise<Response>;
  verifyOrder: (paymentId: string, endpoint: string) => Promise<Response>;
  data: FormSubmissionB2C;
  container: string;
};

export default function PaymentProviders(
  setOnState: (state: RequestStatus) => void
) {
  const {
    staticPages: { checkoutPage },
    languageRoute,
  } = useAppSettingsData();

  let orderAbortController = new AbortController();
  const createOrder = useCallback(
    (data: FormSubmissionB2C, endpoint: string) => {
      if (orderAbortController) {
        orderAbortController.abort();

        orderAbortController = new AbortController();
      }
      const signal = orderAbortController.signal;

      return fetch(`${checkoutPage + endpoint}`, {
        signal,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      }).catch((err) => {
        if (signal.aborted) {
          return new Response('', { status: 404 });
        }
        return new Response('', { status: 500 });
      });
    },
    [checkoutPage]
  );

  const verifyOrder = useCallback(
    (paymentId: string, endpoint: string) =>
      fetch(`${checkoutPage + endpoint}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(paymentId),
      }),
    [checkoutPage]
  );

  const initPayment = useCallback(
    ({ id, src, onLoad, args }: InitPaymentType) => {
      if (document.getElementById(id)) return;

      const s = document.createElement('script');
      s.id = id;
      s.src = src;
      document.head.appendChild(s);

      s.addEventListener('load', (e) => {
        onLoad(args);
      });
    },
    []
  );

  return PayEx(
    languageRoute,
    initPayment,
    createOrder,
    verifyOrder,
    setOnState
  );
}
