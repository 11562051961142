import { styled } from 'stitches.config';
import { H3 } from 'Atoms/Typography/Headings/Heading';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import CheckoutPageModel from 'Models/Pages/CheckoutPage/CheckoutPageModel.interface';
import XHtmlArea from 'Molecules/XHtmlArea/XHtmlArea';
import CheckoutSection from './CheckoutSection';

function Insurance() {
  const { insuranceHeading, insuranceBody } =
    useCurrentPage<CheckoutPageModel>();
  return (
    <CheckoutSection>
      <H3 css={{ mt: 0 }}>{insuranceHeading}</H3>
      <TextWrapper>
        <XHtmlArea content={insuranceBody} />
      </TextWrapper>
    </CheckoutSection>
  );
}

const TextWrapper = styled('div', {});

export default Insurance;
