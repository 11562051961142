import { styled } from 'stitches.config';
import { useEffect, useState } from 'react';

import CheckoutSection from './CheckoutSection';

import FormSubmissionB2C from 'Models/Pages/CheckoutPage/FormSubmissionB2C.interface';
import CheckoutPageModel from 'Models/Pages/CheckoutPage/CheckoutPageModel.interface';

import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';

import { H3 } from 'Atoms/Typography/Headings/Heading';
import Checkbox from 'Atoms/Checkbox/Checkbox';
import XHtmlArea from 'Molecules/XHtmlArea/XHtmlArea';

import PayExPayment from './PaymentMethods/PayExPayment';
import KlarnaPayment from './PaymentMethods/KlarnaPayment';
import SantanderPayment from './PaymentMethods/SantanderPayment';
import KlarnaHelpers from '../../Kex/PaymentProviders/KlarnaHelpers';
import {
  KlarnaTyped,
  PaymentTyped,
  SantanderTyped,
} from '../../Models/Payment/PaymentMethodModelTyped';
import { isEmpty } from 'Shared/Common/Helpers';
import { useKexLoadingCircle } from 'Kex/KexLoadingCircle';
import KexCartModel from 'Models/Cart/KexCartModel.interface';

type PropTypes = {
  addressPayload: FormSubmissionB2C;
  validFormData: boolean;
  cart: KexCartModel | null;
};

function CheckoutPaymentMethods({
  addressPayload,
  validFormData,
  cart,
}: PropTypes) {
  const [acceptedTermsConditions, setAcceptedTermsConditions] =
    useState<boolean>(false);
  const [errorTermsConditions, setErrorTermsConditions] =
    useState<boolean>(false);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<string>('');
  const {
    languageRoute,
    staticPages: { checkoutPage: apiUrl },
  } = useAppSettingsData();
  const { checkoutLabels } = useTranslationData();
  const { termsAndConditionsTextAndLink } = useCurrentPage<CheckoutPageModel>();
  const [santander, setSantander] = useState<SantanderTyped>();
  const [klarnaPaymentList, setKlarnaPaymentList] = useState<KlarnaTyped[]>();

  const [, kexLoadingCircleDispatch] = useKexLoadingCircle();

  async function changePaymentMethod(paymentType: string) {
    setCurrentPaymentMethod(paymentType);
  }

  useEffect(() => {
    let isFetchPayments = true;

    kexLoadingCircleDispatch({ type: 'add', from: 'santander payment' });

    fetch(`${apiUrl + 'PaymentMethods?language=' + languageRoute}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(async (res) => {
        if (isFetchPayments) {
          if (res.ok) {
            const data = (await res.json()) as PaymentTyped[];

            const klarna = data.filter(
              (d): d is KlarnaTyped =>
                d.paymentMethodModelType === 'KlarnaPaymentMethodModel'
            );

            setKlarnaPaymentList(klarna);
            if (klarna[0]) {
              KlarnaHelpers.initSDK(klarna[0].clientToken);
            }

            const santanders = data.filter(
              (d): d is SantanderTyped =>
                d.paymentMethodModelType === 'SantanderPaymentModel'
            );

            if (santanders.length > 1) {
              throw Error(`Multiple santander options ${santanders}`);
            }

            if (santanders.length === 1) {
              setSantander(santanders[0]);
            } else {
              setSantander(undefined);
            }
          }
        }

        kexLoadingCircleDispatch({
          type: 'remove',
          from: 'santander payment',
        });
      })
      .catch(() => {
        kexLoadingCircleDispatch({ type: 'remove', from: 'santander payment' });

        throw Error('Something went wrong here');
      });

    return () => {
      isFetchPayments = false;

      kexLoadingCircleDispatch({ type: 'remove', from: 'santander payment' });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  if (!validFormData || !cart || isEmpty(cart.shippingOptionId)) return <></>;

  return (
    <CheckoutSection>
      <H3 css={{ mt: 0 }}>{checkoutLabels.payment}</H3>
      <TermsAndConditions>
        <Checkbox
          isChecked={acceptedTermsConditions}
          text=""
          error={errorTermsConditions}
          onCheck={() => {
            setCurrentPaymentMethod('');
            setAcceptedTermsConditions(!acceptedTermsConditions);
          }}
        />
        <XHtmlArea content={termsAndConditionsTextAndLink} />
      </TermsAndConditions>

      <CheckoutPaymentContainer>
        {klarnaPaymentList && (
          <KlarnaPayment
            addressPayload={addressPayload}
            currentPaymentMethod={currentPaymentMethod}
            onChangePaymentMethod={changePaymentMethod}
            cart={cart}
            setErrorTermsConditions={setErrorTermsConditions}
            acceptedTermsConditions={acceptedTermsConditions}
            klarnaPaymentList={klarnaPaymentList}
          />
        )}

        {cart.includedMotorcycle && (
          <PayExPayment
            addressPayload={addressPayload}
            currentPaymentMethod={currentPaymentMethod}
            onChangePaymentMethod={changePaymentMethod}
            cart={cart}
            setErrorTermsConditions={setErrorTermsConditions}
            acceptedTermsConditions={acceptedTermsConditions}
          />
        )}

        {santander && (
          <SantanderPayment
            total={cart.totalPrice.price}
            subTotal={cart.subTotalPrice.price}
            isTradeIn={cart.appliedCouponDiscount?.isTradeIn}
            tradeInDiscount={cart.appliedCouponDiscount?.tradeInAmount}
            currentPaymentMethod={currentPaymentMethod}
            santander={santander}
            addressPayload={addressPayload}
            onChangePaymentMethod={changePaymentMethod}
            termsAccepted={acceptedTermsConditions}
            setErrorTermsAccepted={() => setErrorTermsConditions(true)}
          />
        )}
      </CheckoutPaymentContainer>
    </CheckoutSection>
  );
}

const CheckoutPaymentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  pt: 2,
});

const TermsAndConditions = styled('div', {
  display: 'flex',
  alignItems: 'center',
  mb: 2,
});

export default CheckoutPaymentMethods;
