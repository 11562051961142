import { styled } from '@stitches/react';
import { InfoIcon } from 'Atoms/Icons';
import { H6 } from 'Atoms/Typography/Headings/Heading';

const ErrorTextWithIcon = ({
  errorHeader,
  errorDescription,
}: {
  errorHeader: string;
  errorDescription: string;
}) => {
  return (
    <>
      <FlexContainer>
        <InfoIcon space={true} size={'m'} color={'error'} />
        <H6>{errorHeader}</H6>
      </FlexContainer>
      {errorDescription}
    </>
  );
};

export default ErrorTextWithIcon;

const FlexContainer = styled('div', {
  display: 'flex',
});
