import FormSubmission from 'Models/Pages/CheckoutPage/FormSubmission.interface';
import { isEmpty } from 'Shared/Common/Helpers';
import { FetchCartAndNotifyAll } from 'Shared/Cart/Cart';

export type RequestStatus = 'error' | 'loading' | 'success' | '';

export type Validation = {
  key: string;
  value: { message: string; code: string };
}[];

export const GetDeliveryMethodUsingZipcode = async (
  zipCode: string,
  onState: (type: RequestStatus) => void,
  setDeliveryOptions: (value: string) => void
) => {
  onState('loading');

  await fetch(`DeliveryMethods?zipCode=${zipCode}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  })
    .then((res) => {
      if (!res.ok) {
        throw Error('Could not fetch data from resource');
      }
      return res.json();
    })
    .then((data) => {
      if (!isEmpty(data)) {
        setDeliveryOptions(data);
        onState('success');
      } else {
        setDeliveryOptions('');
        onState('error');
      }
    })
    .catch((err) => {
      console.error(err);
      onState('error');
    });
};

export const UpdateCartWithDeliveryInfo = async (
  deliveryData: FormSubmission,
  setValidationMessage: (value: Validation) => void,
  languageRoute: string
) => {
  setValidationMessage([]);
  await fetch(`${'DeliveryMethods'}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(deliveryData),
  })
    .then((res) => {
      if (!res.ok) {
        throw Error('Could not fetch data from resource');
      }
      return res.json();
    })
    .then(
      ({
        validationResult,
        success,
      }: {
        validationResult: Validation;
        success: boolean;
      }) => {
        if (success) {
          FetchCartAndNotifyAll(languageRoute);
        }
        setValidationMessage(validationResult);
      }
    )
    .catch((err) => {
      console.error(err);
      setValidationMessage(err);
    });
};
