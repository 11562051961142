type Value = number | string;

type State = {
  inputText: string;
  value: number;
  valid: boolean;
};

export const santanderReducer =
  (min: number, max: number) =>
  (state: State, value: Value): State => {
    if (typeof value === 'number') {
      const clampedValue = Math.min(max, Math.max(min, value));
      return {
        ...state,
        value: clampedValue,
        inputText: clampedValue.toString(),
        valid: true,
      };
    } else {
      if (value.length === 0) {
        return {
          ...state,
          inputText: '',
          valid: false,
        };
      }

      const parsedValue = Number.parseInt(value);

      const valid = value === parsedValue.toString();

      if (!valid) {
        return {
          ...state,
          valid: false,
        };
      }

      if (parsedValue > max) {
        return {
          ...state,
          value: max,
          inputText: max.toString(),
          valid: true,
        };
      }

      return {
        ...state,
        value: parsedValue,
        inputText: value,
        valid: parsedValue >= min && parsedValue <= max,
      };
    }
  };
