import { ChevronIcon } from 'Atoms/Icons';
import RadioButton from 'Atoms/RadioButton/RadioButton';
import { useRef, useState } from 'react';
import { isEmpty } from 'Shared/Common/Helpers';
import useOutsideClick from 'Shared/Hooks/useOutsideClick';
import { styled } from 'stitches.config';
import { timings, animation } from 'Theme/Settings/animation';

type PropTypes = {
  values: any[] | undefined;
  currentSelected: string;
  placeholderLabel?: string;
  setSelectedValue: (value: string) => void;
  fullWidth?: true;
};

export function DeliveryAgentDropDown({
  values,
  currentSelected,
  setSelectedValue,
  placeholderLabel,
  fullWidth,
}: PropTypes) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  function onSetIsOpen() {
    setIsOpen(!isOpen);
  }

  const getAgentValues = () => {
    const agent = values?.filter((agent: any) => {
      return agent.id === currentSelected;
    });

    if (agent && !isEmpty(agent[0])) {
      return `${agent[0].name}, ${agent[0].address}, ${agent[0].city}`;
    } else {
      return '';
    }
  };

  return (
    <DropDownContainer ref={ref} fullWidth={fullWidth}>
      <DropDownHeader
        onClick={onSetIsOpen}
        isOpen={isOpen}
        fullWidth={fullWidth}
      >
        <CurrentValue>
          {getAgentValues() ? getAgentValues() : placeholderLabel}
        </CurrentValue>
        <ChevronIcon
          size="m"
          css={{
            m: 0,
            transform: isOpen ? 'rotate(180deg)' : 'rotate(360deg)',
          }}
          color="secondary"
        />
      </DropDownHeader>

      <DropDownChildren isOpen={isOpen}>
        {values &&
          values.length > 0 &&
          values.map((value: any, index: number) => (
            <DropDownItem
              key={index}
              onClick={() => {
                setSelectedValue(value.id);
                setIsOpen(false);
              }}
            >
              <DropDownItemLabel>
                <RadioButton
                  useAbsolute
                  css={{ minHeight: '24px' }}
                  isChecked={value.id === currentSelected}
                  text={`${value.name}, ${value.address}, ${value.city}`}
                  value={value.id}
                  onChange={() => {}}
                />
              </DropDownItemLabel>
            </DropDownItem>
          ))}
      </DropDownChildren>
    </DropDownContainer>
  );
}

export default DeliveryAgentDropDown;

const DropDownItemLabel = styled('div', {});

const DropDownContainer = styled('div', {
  w: 'fit-content',
  position: 'relative',
  h: 'auto',
  display: 'block',
  textAlign: 'left',
  variants: {
    fullWidth: {
      true: {
        width: '100%',
      },
    },
  },
});

const DropDownHeader = styled('div', {
  width: '180px',
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between',
  py: 2,
  px: 4,
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: '$primary2',
  transition: `background-color ${timings.threeTenths} ${animation.timingFn}`,
  variants: {
    isOpen: {
      true: {
        backgroundColor: '$primary',
      },
    },
    fullWidth: {
      true: {
        width: '100%',
      },
    },
  },
});

const DropDownChildren = styled('div', {
  lineHeight: '$lh133',
  p: 4,
  h: 'auto',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid $dropdownBorder',
  backgroundColor: '$dropdownChildrenBackground',
  w: '100%',
  opacity: 0,
  transition: `all ${timings.threeTenths} ${animation.timingFn}`,
  overflow: 'hidden',
  position: 'absolute',
  top: 40,
  zIndex: '-1',
  variants: {
    isOpen: {
      true: {
        opacity: 1,
        zIndex: '$DropDown',
      },
    },
  },
});

const DropDownItem = styled('div', {
  cursor: 'pointer',
  w: '-webkit-fill-available',
  h: '100%',
  textAlign: 'left',
  '&:focus': {
    boxShadow: '0 0 0 2px #FFF',
  },
  color: '$dropdownText',
  fs: 6,
  pb: 4,
  display: 'flex',
  justifyContent: 'space-between',
  '&:hover label': {
    textDecoration: 'underline',
  },
});

const CurrentValue = styled('span', {
  color: '$dropdownText',
  fontFamily: '$fontPrimary400',
  wordSpacings: '$wordSpacings$fontPrimary',
  lineHeight: '$lh133',
  ls: '$lhn1',
  fs: 8,
  wordSpacing: '$wordSpacings$fontPrimary',
});
