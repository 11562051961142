import Checkbox from 'Atoms/Checkbox/Checkbox';
import FormTitle from 'Atoms/FormTitle/FormTitle';
import Input from 'Atoms/Input/Input';
import { InputValidation } from 'Atoms/Input/InputValidation';
import { H3 } from 'Atoms/Typography/Headings/Heading';
import FormSubmissionB2C from 'Models/Pages/CheckoutPage/FormSubmissionBaseModel.interface';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import { styled } from 'stitches.config';
import CheckoutSection from './CheckoutSection';
import CheckoutPageModel from 'Models/Pages/CheckoutPage/CheckoutPageModel.interface';
import { accountFormValidation } from 'Shared/Common/AccountFormValidation';

import Insurance from './Insurance';
import { useUserStateData } from 'Shared/Providers/UserContextProvider';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import DeliveryMethods from './DeliveryMethods/DeliveryMethods';
import { Validation } from './DeliveryMethods/DeliveryMethodAPI';
import { getUrlParameter } from '../../Shared/Common/Helpers';
import PaymentProviders from './PaymentProviders';

type PropTypes = {
  includedMotorcycle: boolean;
  continueAsGuest: boolean;
  revalidateCart: () => void;
};

function CheckoutCustomerInformation({
  includedMotorcycle,
  continueAsGuest,
  revalidateCart,
}: PropTypes) {
  const { languageRoute, marketCountryThreeLetterCountryCode } =
    useAppSettingsData();
  const { checkoutLabels, validationLabels } = useTranslationData();
  const { user } = useUserStateData();

  const {
    shippingAddress: existingShippingAddress,
    billingAddress: existingBillingAddress,
    personalNumber: existingPersonalNumber,
  } = useCurrentPage<CheckoutPageModel>();

  useEffect(() => {
    setEmail(user.email);
    setPhone(user.phoneNumber);
  }, [user.authenticated, user.isLoggedWithBankId, user]);

  const [email, setEmail] = useState<string>(user.email || '');
  const [phone, setPhone] = useState<string>(user.phoneNumber || '');
  const [shippingFirstName, setShippingFirstName] = useState<string>(
    existingShippingAddress.firstName || ''
  );
  const [shippingLastName, setShippingLastName] = useState<string>(
    existingShippingAddress.lastName || ''
  );
  const [shippingAddress, setShippingAddress] = useState<string>(
    existingShippingAddress.address || ''
  );
  const [shippingCity, setShippingCity] = useState<string>(
    existingShippingAddress.city || ''
  );
  const [shippingPostalCode, setShippingPostalCode] = useState<string>(
    existingShippingAddress.postalCode || ''
  );

  const [useSameShippingAddress, setUseSameShippingAddress] = useState<boolean>(
    JSON.stringify(existingBillingAddress) ===
      JSON.stringify(existingShippingAddress)
      ? true
      : false
  );
  const [billingFirstName, setBillingFirstName] = useState<string>(
    existingBillingAddress.firstName || ''
  );
  const [billingLastName, setBillingLastName] = useState<string>(
    existingBillingAddress.lastName || ''
  );
  const [billingAddress, setBillingAddress] = useState<string>(
    existingBillingAddress.address || ''
  );
  const [billingCity, setBillingCity] = useState<string>(
    existingBillingAddress.city || ''
  );
  const [billingPostalCode, setBillingPostalCode] = useState<string>(
    existingBillingAddress.postalCode || ''
  );

  const [validFormData, setValidFormData] = useState<boolean>(false);

  const [backendValidation, setBackendValidation] = useState(
    new Map<string, { message: string; code: string }>()
  );

  const addressPayload: FormSubmissionB2C = useMemo(
    () => ({
      billingAddress,
      billingCity,
      billingCountry: marketCountryThreeLetterCountryCode,
      billingName: billingFirstName,
      billingLastName,
      billingPostalCode,
      email,
      name: shippingFirstName,
      phoneNumber: phone,
      sameAsShippingAddress: `${useSameShippingAddress}`,
      shippingAddress,
      shippingCity,
      shippingCountry: marketCountryThreeLetterCountryCode,
      shippingName: shippingFirstName,
      shippingLastName,
      shippingPostalCode,
      personalNumber: existingPersonalNumber,
    }),
    [
      billingAddress,
      billingCity,
      billingFirstName,
      billingLastName,
      billingPostalCode,
      email,
      existingPersonalNumber,
      marketCountryThreeLetterCountryCode,
      phone,
      shippingAddress,
      shippingCity,
      shippingFirstName,
      shippingLastName,
      shippingPostalCode,
      useSameShippingAddress,
    ]
  );

  const verifyPayEx = PaymentProviders(() => {}).verifyPayEx;
  const verifyPayExPayment = getUrlParameter('verifyPayExPayment') === 'true';

  useEffect(() => {
    if (verifyPayExPayment) {
      verifyPayEx(languageRoute);
    }
  }, [languageRoute, verifyPayExPayment, verifyPayEx]);

  const onInputsValid = () => {
    setValidFormData(true);
  };

  const onInputsInvalid = () => {
    setValidFormData(false);
  };

  const onValidation = useCallback((validation: Validation) => {
    setBackendValidation(
      new Map(validation.map(({ key, value }) => [key, value]))
    );
  }, []);

  const clearBackendValidation = (key: string) => {
    const newDict = new Map(backendValidation);
    newDict.delete(key);
    setBackendValidation(newDict);
  };

  const inputBackground: any = {
    inputBackground: 'primary4',
  };

  // No Motorcycle in cart and user are not BankIdAuthenticated
  if (includedMotorcycle && !user.isLoggedWithBankId) return <></>;

  // User has not pressed continueAsGuest and is not authenticated
  if (!continueAsGuest && !user.authenticated) return <></>;

  return (
    <>
      <CheckoutSection>
        <FormContainer>
          <InputValidation
            onInputsValid={onInputsValid}
            onInputsInvalid={onInputsInvalid}
          >
            <FormInputContainer>
              <H3 css={{ mt: 0 }}>{checkoutLabels.contactInformation}</H3>
              <Row css={{ mb: 0 }}>
                <Column>
                  <Input
                    title={checkoutLabels.name}
                    placeholder={checkoutLabels.name}
                    onChange={setShippingFirstName}
                    name="Shipping Name"
                    validation={{
                      required: true,
                      minLength: 1,
                      maxLength: 40,
                      errorMessage: validationLabels.invalidName,
                      backendValidation: backendValidation.get('Shipping Name'),
                      onTouched: clearBackendValidation,
                    }}
                    autoComplete="given-name"
                    defaultValue={shippingFirstName}
                    {...inputBackground}
                  />
                </Column>
                <Column>
                  <Input
                    title={checkoutLabels.lastName}
                    placeholder={checkoutLabels.lastName}
                    onChange={setShippingLastName}
                    name="Shipping Last Name"
                    validation={{
                      minLength: 1,
                      maxLength: 40,
                      errorMessage: validationLabels.invalidLastName,
                      backendValidation:
                        backendValidation.get('Shipping Last Name'),
                      onTouched: clearBackendValidation,
                    }}
                    autoComplete="family-name"
                    defaultValue={shippingLastName}
                    {...inputBackground}
                  />
                </Column>
              </Row>

              <Input
                title={checkoutLabels.email}
                placeholder={checkoutLabels.email}
                onChange={setEmail}
                name="Email"
                validation={{
                  ...accountFormValidation.email,
                  errorMessage: validationLabels.invalidEmail,
                  backendValidation: backendValidation.get('Email'),
                  onTouched: clearBackendValidation,
                }}
                autoComplete="email"
                defaultValue={email}
                disabled={user.authenticated}
                {...inputBackground}
              />
              <Input
                title={checkoutLabels.phoneNumber}
                placeholder={checkoutLabels.phoneNumber}
                onChange={setPhone}
                name="Phone Number"
                validation={{
                  pattern: /(^[\\s\-+()0-9]{1,16}$)/,
                  errorMessage: validationLabels.invalidPhoneNumber,
                  backendValidation: backendValidation.get('Phone Number'),
                  onTouched: clearBackendValidation,
                }}
                autoComplete="tel"
                defaultValue={phone}
                {...inputBackground}
              />
            </FormInputContainer>

            <FormInputContainer>
              <FormTitle>{checkoutLabels.deliveryAddress}</FormTitle>
              <Input
                title={checkoutLabels.address}
                placeholder={checkoutLabels.address}
                onChange={setShippingAddress}
                name="Shipping Address"
                validation={{
                  minLength: 1,
                  maxLength: 40,
                  errorMessage: validationLabels.invalidAddress,
                  backendValidation: backendValidation.get('Shipping Address'),
                  onTouched: clearBackendValidation,
                }}
                autoComplete="street-address"
                defaultValue={shippingAddress}
                {...inputBackground}
              />

              <Row>
                <Column>
                  <Input
                    title={checkoutLabels.zipCode}
                    placeholder={checkoutLabels.zipCode}
                    onChange={setShippingPostalCode}
                    name="Shipping PostalCode"
                    validation={{
                      minLength: 1,
                      maxLength: 10,
                      errorMessage: validationLabels.invalidZipCode,
                      backendValidation: backendValidation.get(
                        'Shipping PostalCode'
                      ),
                      onTouched: clearBackendValidation,
                    }}
                    autoComplete="postal-code"
                    defaultValue={shippingPostalCode}
                    {...inputBackground}
                  />
                </Column>
                <Column>
                  <Input
                    title={checkoutLabels.city}
                    placeholder={checkoutLabels.city}
                    onChange={setShippingCity}
                    name="Shipping City"
                    validation={{
                      maxLength: 20,
                      required: false,
                      errorMessage: validationLabels.invalidCity,
                      backendValidation: backendValidation.get('Shipping City'),
                      onTouched: clearBackendValidation,
                    }}
                    autoComplete="home city"
                    defaultValue={shippingCity}
                    {...inputBackground}
                  />
                </Column>
              </Row>
            </FormInputContainer>

            <FormInputContainer>
              <Checkbox
                text={checkoutLabels.alternativeShippingAddress}
                onCheck={() =>
                  setUseSameShippingAddress(!useSameShippingAddress)
                }
                isChecked={useSameShippingAddress}
                id="alternative-shipping-address"
              />
            </FormInputContainer>

            {!useSameShippingAddress && (
              <>
                <Row css={{ mb: 0 }}>
                  <Column>
                    <Input
                      title={checkoutLabels.name}
                      placeholder={checkoutLabels.name}
                      onChange={setBillingFirstName}
                      name="Billing Name"
                      validation={{
                        active: !useSameShippingAddress,
                        minLength: 1,
                        maxLength: 40,
                        errorMessage: validationLabels.invalidName,
                        backendValidation:
                          backendValidation.get('Billing Name'),
                        onTouched: clearBackendValidation,
                      }}
                      autoComplete="given-name"
                      defaultValue={billingFirstName}
                      {...inputBackground}
                    />
                  </Column>
                  <Column>
                    <Input
                      title={checkoutLabels.lastName}
                      placeholder={checkoutLabels.lastName}
                      onChange={setBillingLastName}
                      name="Billing Last Name"
                      validation={{
                        active: !useSameShippingAddress,
                        minLength: 1,
                        maxLength: 40,
                        errorMessage: validationLabels.invalidLastName,
                        backendValidation:
                          backendValidation.get('Billing Last Name'),
                        onTouched: clearBackendValidation,
                      }}
                      autoComplete="family-name"
                      defaultValue={billingLastName}
                      {...inputBackground}
                    />
                  </Column>
                </Row>
                <Input
                  title={checkoutLabels.address}
                  placeholder={checkoutLabels.address}
                  onChange={setBillingAddress}
                  name="Billing Address"
                  validation={{
                    active: !useSameShippingAddress,
                    minLength: 1,
                    maxLength: 40,
                    errorMessage: validationLabels.invalidAddress,
                    backendValidation: backendValidation.get('Billing Address'),
                    onTouched: clearBackendValidation,
                  }}
                  autoComplete="street-address"
                  defaultValue={billingAddress}
                  {...inputBackground}
                />
                <Row>
                  <Column>
                    <Input
                      title={checkoutLabels.zipCode}
                      placeholder={checkoutLabels.zipCode}
                      onChange={setBillingPostalCode}
                      name="Billing PostalCode"
                      validation={{
                        active: !useSameShippingAddress,
                        minLength: 1,
                        maxLength: 10,
                        errorMessage: validationLabels.invalidZipCode,
                        backendValidation:
                          backendValidation.get('Billing PostalCode'),
                        onTouched: clearBackendValidation,
                      }}
                      autoComplete="postal-code"
                      defaultValue={billingPostalCode}
                      {...inputBackground}
                    />
                  </Column>
                  <Column>
                    <Input
                      title={checkoutLabels.city}
                      placeholder={checkoutLabels.city}
                      onChange={setBillingCity}
                      name="Billing City"
                      validation={{
                        active: !useSameShippingAddress,
                        minLength: 1,
                        maxLength: 20,
                        errorMessage: validationLabels.invalidCity,
                        backendValidation:
                          backendValidation.get('Billing City'),
                        onTouched: clearBackendValidation,
                      }}
                      autoComplete="home city"
                      defaultValue={billingCity}
                      {...inputBackground}
                    />
                  </Column>
                </Row>
              </>
            )}
          </InputValidation>
        </FormContainer>
      </CheckoutSection>

      {includedMotorcycle && <Insurance />}
      <DeliveryMethods
        addressPayload={addressPayload}
        validFormData={validFormData}
        onValidation={onValidation}
      />
    </>
  );
}

export default CheckoutCustomerInformation;

const FormContainer = styled('form', {
  maxWidth: '495px',
});

const FormInputContainer = styled('div', {
  mb: 6,
});

const Row = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  mx: -3,
  mb: 6,
  '@mediaMinLarge': {
    flexDirection: 'row',
  },
});

const Column = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  w: '100%',
  px: 3,
});
