import { ReactNode } from 'react';
import { styled } from 'stitches.config';

type PropTypes = {
  children: ReactNode;
};
function CheckoutSection({ children }: PropTypes) {
  return <CheckoutSectionContainer>{children}</CheckoutSectionContainer>;
}

const CheckoutSectionContainer = styled('div', {
  position: 'relative',
  height: 'fit-content',
  py: 8,
  px: 4,
  backgroundColor: '$checkoutPageBackgroundSecondary',
  '@mediaMinLarge': {
    p: 8,
  },
});

export default CheckoutSection;
